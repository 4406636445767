import {PropsWithChildren, ReactElement} from "react";
import * as Sentry from "@sentry/react";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {addError} from "@/redux/meta/metaActions";
import {Provider, useDispatch} from "react-redux";
import {StatsigProvider} from "statsig-react";
import {ThirdwebProvider} from "devour-thirdweb/react";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux";

function DevourProviders(props: PropsWithChildren): ReactElement {
    const dispatch = useDispatch();

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: async (error: any) => {

                /*
                 * Wagmi library throws random errors that are not causing actual problems.
                 * So we are ignoring these errors.
                 */
                const ignoreErrors = [
                    "Connector not connected",
                    "connector2.getAccounts is not a function",
                    "1.getAccounts is not a function",
                    "getAccounts is not a function",
                    "connection.connector.getProvider is not a function",
                    "n.connector.getProvider is not a function",
                ];

                if (error && !ignoreErrors.some((msg) => error.message?.includes(msg))) {
                    dispatch(await addError(error));
                }
            },
        }),
    });

    return (
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <StatsigProvider
                sdkKey={import.meta.env.VITE_STATSIG_CLIENT_API_KEY}
                waitForInitialization={true}
                user={{
                    userID: "",
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <ThirdwebProvider>
                        {props.children}
                    </ThirdwebProvider>
                </QueryClientProvider>
            </StatsigProvider>
        </PersistGate>
    );
}

export default Sentry.withProfiler(DevourProviders);
