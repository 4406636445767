import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import RestaurantInitialAddressSearch from "@/pages/restaurants/components/RestaurantInitialAddressSearch";
import { isDesktop, isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";
import {LinkProps} from "react-router";
import { toggleLoginOpen } from "@/redux/meta/metaActions";
import OnboardingModal from "@/components/modals/onboardingModal/OnboardingModal";
import useTabIsClosing from "@/hooks/useTabIsClosing";
import useInactive from "@/hooks/useInactive";
import getAnonymousSpin from "@/utils/getAnonymousSpin";
import { AnonymousSpinModalShownKey } from "@/components/modals/GoVipSpin2WinModal";
import { LandingPageBannerCarousel } from "./landing/LandingPageBannerCarousel";
import { LandingPageImagesCarousel } from "./landing/LandingPageImagesCarousel";
import { FaArrowRight, FaGamepad } from "react-icons/fa";
import { PiForkKnifeFill } from "react-icons/pi";
import { RiVipDiamondLine } from "react-icons/ri";
import { LandingPageEarnRewardsCards } from "./landing/LandingPageEarnRewardsCards";
import { GoArrowUpRight } from "react-icons/go";
import GoVipLevelsModal from "@/components/modals/GoVipLevelsModal";
import GoVipOWTutorialModal from "@/components/goVip/GoVipOWTutorialModal";
import { LandingPageFaq } from "./landing/LandingPageFaq";

const SOCIALS = [
    {
        name: "lp-join-discord.webp",
        link: "https://discord.com/invite/devourplay",
        alt: "discord-icon",
    },
    {
        name: "lp-join-facebook.webp",
        link: "https://www.facebook.com/GoDevour",
        alt: "facebook-icon",
    },
    {
        name: "lp-join-instagram.webp",
        link: "https://www.instagram.com/go_devour/",
        alt: "instagram-icon",
    },
    {
        name: "lp-join-telegram.webp",
        link: "https://t.me/GoDevour",
        alt: "telegram-icon",
    },
    {
        name: "lp-join-twitter.webp",
        link: "https://x.com/GoDevour",
        alt: "twitter-icon",
    },
];

function Landing(): ReactElement {
    const [onboardingOpen, setOnboardingOpen] = useState(false);
    const [isLevelsModalOpen, setIsLevelsModalOpen] = useState(false);
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [spinWheelPromptOpen, setSpinWheelPromptOpen] = useState(false);
    const dispatch = useDispatch();
    const overwolfAppUrl = "https://www.overwolf.com/app/Devour-DevourPlay";


    const handleOpenSpinWheelPrompt = () => {
        const spinModalShown = window[AnonymousSpinModalShownKey];
        const todayAnonymousSpin = getAnonymousSpin();
        if (!fullToken && !spinModalShown && !todayAnonymousSpin) {
            setSpinWheelPromptOpen(true);
        }
    };


    const getFooterBg = () => {
        let mediaQuery = "sm";
        if (isTablet) {
            mediaQuery = "md";
        } else if (isDesktop) {
            mediaQuery = "lg";
        }

        return `${import.meta.env.VITE_CDN_URL}/images/landing-footer-image-${mediaQuery}.webp`;
    };

    useTabIsClosing(() => {
        if (!isMobile) {
            handleOpenSpinWheelPrompt();
        }
    });

    useInactive(() => {
        if (isMobile) {
            handleOpenSpinWheelPrompt();
        }
    });

    const handleNavigateToOWApp = () => {
        window.open(overwolfAppUrl, "_blank");
    };

    return (
        <>
            <GoVipOWTutorialModal isOpen={isTutorialModalOpen} onClose={() => setIsTutorialModalOpen(false)} />
            <GoVipLevelsModal isOpen={isLevelsModalOpen} toggle={() => setIsLevelsModalOpen(false)} />
            <div className="landing">
                <OnboardingModal
                    isOpen={onboardingOpen}
                    toggle={(openLogin) => {
                        setOnboardingOpen(false);
                        if (openLogin) {
                            dispatch(toggleLoginOpen(true));
                        }
                    }}
                />
                {/* TOP NAVIGATION*/}
                <div className="landing_top-header">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DM-DevourGO_Final-01.webp`}
                        alt="Restaurant Search Background"
                        className="landing_top-header_logo"
                    />
                    {fullToken
                        ? <FrameButton
                            <LinkProps>
                            color="white-drop-shadow"
                            size={isDesktop || isTablet ? "narrow" : "pill"}
                            to="/go-vip"
                        >
                            Go to GoVIP
                        </FrameButton>
                        : <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="white-drop-shadow"
                            size={isDesktop || isTablet ? "narrow" : "pill"}
                            onClick={() => {
                                dispatch(toggleLoginOpen(true));
                            }}
                        >
                            Login
                        </FrameButton>
                    }
                </div>

                {/* HERO SECTION*/}
                <div className="landing_hero" id="restaurant-search">
                    <div className="landing_hero_content">
                        <h1 className="landing_hero_slogan">
                            ARE YOU <span className="landing_hero_slogan_highlight"> HUNGRY?</span>
                        </h1>

                        <span className="landing_hero_description" >
                            Order from your favorite restaurants <span>nationwide in the U.S.</span>
                        </span>
                        <RestaurantInitialAddressSearch simple />
                    </div>
                </div>

                <LandingPageBannerCarousel />

                {/* DevourPlay section*/}
                <div className="landing_devour-play">
                    <div className="landing_devour-play_heading">
                        <div className="landing_devour-play_heading_new-chip">
                            <span>NEW</span>
                        </div>
                        <h2 className="landing_devour-play_heading_title">
                            ORDER FOOD <br /> <span>IN 2000+ GAMES</span>
                        </h2>
                        <p className="landing_devour-play_heading_description">Food Ordering Beyond Traditional Apps</p>
                    </div>

                    <LandingPageImagesCarousel />

                    <FrameButton onClick={handleNavigateToOWApp} color="devourplay-purple" size="normal">
                        Download DevourPlay
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/white-overwolf-icon.webp`}
                            alt="Overwolf logo"
                        />
                    </FrameButton>
                </div>

                {/* Landing video SECTION*/}
                <div className="landing_video">
                    <video
                        src={`${import.meta.env.VITE_CDN_URL}/videos/Landing-page-video.mp4`}
                        muted
                        autoPlay
                        loop
                        controls={false}
                    ></video>
                </div>
                {/* Eat Play Earn SECTION*/}
                <div className="landing_eat-play-earn">
                    <div
                        className="landing_eat-play-earn_heading"
                        style={{
                            backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/lp-devour-play-texture.webp)`,
                        }}
                    >
                        <h4>
                            EAT. {isMobile && !isTablet && <br />} <span>PLAY.</span> {isMobile && !isTablet && <br />}{" "}
                            EARN{" "}
                        </h4>
                        <FrameButton onClick={() => setIsTutorialModalOpen(true)} color="devourplay-purple" size="normal">
                            Get Started
                            <FaArrowRight />
                        </FrameButton>
                    </div>

                    <div className="landing_eat-play-earn_sections">
                        <div
                            className="landing_eat-play-earn_sections_section"
                            style={{
                                backgroundImage: `url(${
                                    import.meta.env.VITE_CDN_URL
                                }/images/lp-order-now-texture.webp)`,
                            }}
                        >
                            <div className="overlay"></div>
                            <div className="landing_eat-play-earn_sections_section_content">
                                <div className="landing_eat-play-earn_sections_section_content_chip">
                                    <PiForkKnifeFill /> <span>EAT</span>{" "}
                                </div>
                                <div className="landing_eat-play-earn_sections_section_content_info">
                                    <h4>
                                        ORDER <span>ANYTIME</span>
                                    </h4>
                                    <p>
                                        Get food delivered straight to you — whether you’re on your phone, laptop, or
                                        online games!
                                    </p>
                                </div>
                                <FrameButton to="#restaurant-search" color="white-outline" size="narrow">
                                    Order Food <FaArrowRight />
                                </FrameButton>
                            </div>
                            <div
                                className="landing_eat-play-earn_sections_section_image"
                                style={{
                                    backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/landing-eat-${
                                        isDesktop ? "lg" : "sm"
                                    }.webp)`,
                                }}
                            ></div>
                        </div>
                        <div
                            className="landing_eat-play-earn_sections_section swift-on-lg"
                            style={{
                                backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/lp-levelup-texture.webp)`,
                            }}
                        >
                            <div className="overlay"></div>
                            <div className="landing_eat-play-earn_sections_section_content">
                                <div className="landing_eat-play-earn_sections_section_content_chip">
                                    <FaGamepad /> <span>PLAY</span>
                                </div>
                                <div className="landing_eat-play-earn_sections_section_content_info">
                                    <h4>
                                        EARN XP <span>YOUR WAY</span>
                                    </h4>
                                    <p>
                                        Play games, complete quests, spin the wheel daily, or place food orders to level
                                        up and unlock rewards.
                                    </p>
                                </div>
                                <FrameButton
                                    onClick={() => dispatch(toggleLoginOpen(true))}
                                    color="white-outline"
                                    size="narrow"
                                >
                                    Sign up and get bonus XP <RiVipDiamondLine />
                                </FrameButton>
                            </div>
                            <div
                                className="landing_eat-play-earn_sections_section_image"
                                style={{
                                    backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/landing-play-${
                                        isDesktop ? "lg" : "sm"
                                    }.webp)`,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div
                        className="landing_eat-play-earn_earn-section"
                        style={{
                            backgroundImage: `url(${
                                import.meta.env.VITE_CDN_URL
                            }/images/landing-earn-bg-texture-lg.webp)`,
                        }}
                    >
                        <div className="landing_eat-play-earn_sections_section_content center-on-lg">
                            <div className="landing_eat-play-earn_sections_section_content_chip">
                                <RiVipDiamondLine /> <span>EARN</span>{" "}
                            </div>
                            <div className="landing_eat-play-earn_sections_section_content_info">
                                <h4>
                                    <span>EARN</span> {!isMobileOnly && "YOUR"} REWARDS
                                </h4>
                                <p>Level up! Epic rewards at every level awaits</p>
                            </div>
                            <FrameButton onClick={() => setIsLevelsModalOpen(true)} color="white-outline" size="narrow">
                                View Level Rewards <GoArrowUpRight />
                            </FrameButton>
                        </div>
                        <LandingPageEarnRewardsCards />
                    </div>
                </div>

                {/* Get the app section*/}
                <div className="landing_get-the-app">
                    <div className="landing_get-the-app_heading">
                        <h5>GET THE APP</h5>
                        <p>Download DevourPlay on Overwolf</p>
                        <FrameButton onClick={handleNavigateToOWApp} color="white-outline" size="narrow">
                            <img
                                src={`${import.meta.env.VITE_CDN_URL}/images/white-overwolf-icon.webp`}
                                alt="Overwolf logo"
                            />{" "}
                            Overwolf
                        </FrameButton>
                    </div>
                    <div className="landing_get-the-app_showcase">
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/lp-ow-showcase.webp`}
                            alt="overwolf showcase"
                        />
                    </div>
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/lp-3d-delivery.webp`}
                        className="landing_get-the-app_left-texture"
                        alt="left texture"
                    />
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/lp-3d-food.webp`}
                        className="landing_get-the-app_right-texture"
                        alt="right texture"
                    />
                </div>
                <div className="landing_paddings">
                    {/* Got questions section */}
                    <div className="landing_got-questions">
                        <div className="landing_got-questions_heading">
                            <h5>
                                GOT <span>QUESTIONS</span>?
                            </h5>
                            <p>Frequently Asked Questions</p>
                        </div>
                        <LandingPageFaq/>
                    </div>

                    {/* Join the fam section */}
                    <div className="landing_join-the-fam">
                        <div className="landing_join-the-fam_heading">
                            <h5>JOIN OUR FAM</h5>
                            <p>Welcome to the good-vibes-only community :)</p>
                        </div>
                        <div className="landing_join-the-fam_social-icons">
                            {
                                SOCIALS.map((socialData) =>
                                    <a href={socialData.link} target="_blank" rel="noreferrer">
                                        <img
                                            src={`${import.meta.env.VITE_CDN_URL}/images/${socialData.name}`}
                                            alt={socialData.alt}
                                        />
                                    </a>)
                            }

                        </div>
                    </div>
                </div>

                <div
                    className="landing_footer"
                    style={{
                        backgroundImage: `url(${getFooterBg()})`,
                    }}
                >
                    <h5>GAMERS GOTTA EAT!</h5>
                </div>
            </div>
        </>
    );
}

export default Landing;
