import {ReactElement, useState} from "react";
import {WalletId, injectedProvider} from "devour-thirdweb/wallets";
import {thirdwebWalletList, ThirdWebWalletListItem} from "@/data/thirdwebWalletList";
import WalletConnectListButton from "@/components/WalletConnectListButton";

interface Props {
    onSelect: (walletId: WalletId, loadingOverlay?: boolean) => void;
}

function WalletConnectList(props: Props): ReactElement {
    const [searchValue, setSearchValue] = useState<string>("");

    function sortWallets(a: ThirdWebWalletListItem, b: ThirdWebWalletListItem): number {
        // Sort by installed status
        const aWalletInstalled = injectedProvider(a.id);
        const bWalletInstalled = injectedProvider(b.id);
        if (aWalletInstalled && !bWalletInstalled) return -1;
        if (!aWalletInstalled && bWalletInstalled) return 1;

        return 0;
    }

    function filterWallets(wallet: ThirdWebWalletListItem): boolean {
        if (!searchValue) {
            return true;
        }
        return wallet.name.toLowerCase().includes(searchValue.toLowerCase());
    }

    return (
        <div className="wallet-connect-list">
            <div className="wallet-connect-list_search">
                <input
                    type="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search for a wallet"
                />
            </div>
            <div className="wallet-connect-list_items">
                {thirdwebWalletList
                    .filter(filterWallets)
                    .sort(sortWallets)
                    .map((wallet) =>
                        <WalletConnectListButton
                            key={wallet.id}
                            onSelect={props.onSelect}
                            wallet={wallet}
                        />)}
            </div>
        </div>
    );
}

export default WalletConnectList;
