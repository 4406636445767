import {ReactElement, useEffect} from "react";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router";
import * as Sentry from "@sentry/react";
import DevourProviders from "./DevourProviders";
import PageWrapper from "./components/routing/PageWrapper";
import ComponentDemoPage from "./pages/ComponentDemoPage";
import AccountPage from "./pages/AccountPage";
import WithdrawPage from "./pages/WithdrawPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import DevourPlayLandingPage from "./pages/DevourPlayLandingPage";
import RestaurantSearchResultsPage from "./pages/restaurants/RestaurantSearchResultsPage";
import RestaurantPage, {DEVOUR_RESTAURANT_ID} from "./pages/restaurants/RestaurantPage";
import BuyDPay from "./pages/BuyDPay";
import MenuOrderListPage from "./pages/menu-orders/MenuOrderListPage";
import MenuOrderFailPage from "./pages/menu-orders/MenuOrderFailPage";
import MobileReferAFriendPage from "./pages/MobileReferAFriendPage";
import RestaurantFavoritesPage from "./pages/restaurants/RestaurantFavoritesPage";
import GoVip from "./pages/GoVip";
import LoginPage from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import Landing from "@/pages/Landing";
import GoFrensPage from "@/pages/GoFrensPage";
import GoFrensMyNfts from "@/pages/gofrens/GoFrensMyNfts";
import TheIndustryPassPage from "@/pages/TheIndustryPassPage";
import GoFrensSearchResultsPage from "@/pages/gofrens/GoFrensSearchResultsPage";
import {isDev} from "@/utils/isDev";
import CheckoutPage from "@/pages/CheckoutPage";
import MobileAffiliatesPage from "@/pages/MobileAffiliatesPage";
import RestaurantMapLandingPage from "@/pages/brandMap/RestaurantMapLandingPage";
import useOverwolfInterop from "./hooks/useOverwolfInterop";
import OverwolfLandingPage from "./pages/overwolf/OverwolfLandingPage";
import OverwolfGoVip from "@/pages/overwolf/OverwolfGoVip";
import MenuOrderSuccessSwitcher from "./components/menuOrder/MenuOrderSuccessSwitcher";
import OverwolfDiscoverPage from "./pages/overwolf/OverwolfDiscoverPage";
import GuildsPage from "@/pages/GuildsPage";
import OverwolfGatherPage from "./pages/overwolf/OverwolfGatherPage";
import {CollectiblesPage} from "./pages/CollectiblesPage";
import InitialRedirect from "@/components/InitialRedirect";

function App(): ReactElement {

    const {setupOverwolf} = useOverwolfInterop();

    useEffect(() => {
        setupOverwolf();
    }, []);

    const errorElement =
				<PageWrapper
				    page={<ErrorPage/>}
				    sidebarDockingEnabled={true}
				    authRequired={false}
				/>;

    return (
        <DevourProviders>
            <RouterProvider
                router={Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
                    isDev() && {
                        path: "/demo",
                        element:
														<PageWrapper
														    page={<ComponentDemoPage/>}
														    sidebarDockingEnabled={true}
														/>
                        ,
                    },
                    {
                        path: "/",
                        element:
														<InitialRedirect checkOverwolf={true}/>
                        ,
                    },
                    {
                        path: "/home",
                        element:
														<PageWrapper
														    page={<Landing/>}
														    sidebarDockingEnabled={false}
														    authRequired={false}
														    showMobileStickyFooter={false}
														/>
                        ,
                    },
                    {
                        path: "/overwolf-landing",
                        element:
														<PageWrapper
														    page={<OverwolfLandingPage/>}
														    sidebarDockingEnabled={false}
														    showFooter={false}
														    showMobileStickyFooter={false}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/overwolf-discover",
                        element:
														<PageWrapper
														    page={<OverwolfDiscoverPage/>}
														    sidebarDockingEnabled={false}
														    showFooter={false}
														    showMobileStickyFooter={false}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/overwolf-gather",
                        element:
														<PageWrapper
														    page={<OverwolfGatherPage/>}
														    sidebarDockingEnabled={false}
														    showFooter={false}
														    showMobileStickyFooter={false}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/overwolf-go-vip",
                        element:
														<PageWrapper
														    page={<OverwolfGoVip/>}
														    sidebarDockingEnabled={false}
														    showFooter={false}
														    showMobileStickyFooter={false}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/log-in",
                        element:
														<PageWrapper
														    page={<LoginPage/>}
														    sidebarDockingEnabled={true}
														/>
                        ,
                    },
                    {
                        path: "/account",
                        element:
														<PageWrapper
														    page={<AccountPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/dpay-load",
                        element:
														<PageWrapper
														    page={<AccountPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/favorites",
                        element:
														<PageWrapper
														    page={<RestaurantFavoritesPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/refer-a-friend",
                        element:
														<PageWrapper
														    page={<MobileReferAFriendPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/affiliates",
                        element:
														<PageWrapper
														    page={<MobileAffiliatesPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/gofrens",
                        element:
														<Navigate
														    to="/gofriends"
														    replace={true}
														/>
                        ,
                    },
                    {
                        path: "/gofriends",
                        element:
														<PageWrapper
														    page={<GoFrensPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/guilds",
                        element:
														<PageWrapper
														    page={<GuildsPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														/>,
                    },
                    {
                        path: "/the-industry-pass/",
                        element:
														<PageWrapper
														    page={<TheIndustryPassPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														/>,
                    },
                    {
                        path: "/the-industry-pass/:nftPurchaseId",
                        element:
														<PageWrapper
														    page={<TheIndustryPassPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														/>,

                    },
                    /*
                     *{
                     *  path: "/gofriends-rewards",
                     *  element:
                     *      <PageWrapper
                     *          page={<GoFrensRewardsPage/>}
                     *          sidebarDockingEnabled={true}
                     *          authRequired={true}
                     *      />
                     *  ,
                     *},
                     */
                    {
                        path: "/gofriends-my-nfts",
                        element:
														<PageWrapper
														    page={<GoFrensMyNfts/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/gofriends/search-results",
                        element:
														<PageWrapper
														    page={<GoFrensSearchResultsPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>,

                    },
                    {
                        path: "/go-vip-dashboard",
                        element:
														<PageWrapper
														    page={<GoVip/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/go-vip",
                        element:
														<Navigate
														    to={"/go-vip-dashboard"}
														    replace={true}
														/>
                        ,
                    },
                    {
                        path: "/withdraw",
                        element:
														<PageWrapper
														    page={<WithdrawPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/buy-dpay",
                        element:
														<Navigate
														    to="/load-fuel"
														    replace={true}
														/>
                        ,
                    },
                    {
                        path: "/load-fuel",
                        element:
														<PageWrapper
														    page={<BuyDPay/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/privacy-policy",
                        element:
														<PageWrapper
														    page={<PrivacyPolicyPage/>}
														    sidebarDockingEnabled={true}
														/>
                        ,
                    },
                    {
                        path: "/terms-of-use",
                        element:
														<PageWrapper
														    page={<TermsOfUsePage/>}
														    sidebarDockingEnabled={true}
														/>
                        ,
                    },
                    {
                        path: "/devourplay",
                        element:
														<PageWrapper
														    page={<DevourPlayLandingPage/>}
														    sidebarDockingEnabled={false}
														    showFooter={false}
														    showMobileStickyFooter={false}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/cookie-policy",
                        element:
														<PageWrapper
														    page={<CookiePolicyPage/>}
														    sidebarDockingEnabled={true}
														/>
                        ,
                    },
                    {
                        path: "/brands/:slug",
                        element:
														<PageWrapper
														    page={<RestaurantMapLandingPage/>}
														    sidebarDockingEnabled={false}
														    showMobileStickyFooter={false}
														    authRequired={false}
														    pagePaddingAdjusterClassName="restaurant-map-landing-page-padding-adjuster"
														    showFooter={false}
														    themeExcluded={true}
														    hideLoader={true}
														/>
                        ,
                    },
                    {
                        path: "/restaurants",
                        element:
														<InitialRedirect checkOverwolf={false}/>
                        ,
                    },
                    {
                        path: "/restaurants/search/:placeId",
                        element:
														<PageWrapper
														    page={<RestaurantSearchResultsPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														/>
                        ,
                    },
                    {
                        path: "/restaurants/search",
                        element:
														<Navigate
														    to="/"
														    replace={true}
														/>
                        ,
                    },
                    [
                        "/restaurants/:state/:cityName/:address/:restaurantName/:restaurantId",
                        "/restaurants/:state/:cityName/:address/:restaurantName/:restaurantId/:placeId",
                        "/restaurants/:restaurantId",
                        "/restaurants/:restaurantId/:placeId",
                    ].map(p => ({
                        path: p,
                        element:
														<PageWrapper
														    page={<RestaurantPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={false}
														    controlMobileStickyFooterWithRedux={true}
														/>
                        ,
                    })),
                    {
                        path: "/checkout/:menuOrderId",
                        element:
														<PageWrapper
														    page={<CheckoutPage/>}
														    sidebarDockingEnabled={false}
														    showMobileStickyFooter={false}
														    authRequired={true}
														    showFooter={false}
														/>
                        ,
                    },
                    {
                        path: "/loot-shop",
                        element:
														<Navigate
														    to={`/restaurants/${DEVOUR_RESTAURANT_ID}`}
														    replace={true}
														/>
                        ,
                    },
                    {
                        path: "/collectibles",
                        element:
														<PageWrapper
														    page={<CollectiblesPage/>}
														    showFooter={false}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/menu-orders/",
                        element:
														<PageWrapper
														    page={<MenuOrderListPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/menu-orders/:menuOrderId/success",
                        element:
														<PageWrapper
														    page={<MenuOrderSuccessSwitcher/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/menu-orders/:menuOrderId/fail",
                        element:
														<PageWrapper
														    page={<MenuOrderFailPage/>}
														    sidebarDockingEnabled={true}
														    authRequired={true}
														/>
                        ,
                    },
                    {
                        path: "/*",
                        element:
														<Navigate
														    to={"/"}
														    replace={true}
														/>
                        ,
                    },
                ].flat().map((routeItem) => {
                    // Prepend errorElement to route item
                    return {
                        errorElement,
                        ...routeItem,
                    };
                }))}
            />
        </DevourProviders>
    );
}

export default App;
