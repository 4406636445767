import {ReactElement, ReactNode, useEffect, useState} from "react";
import {ErrorType, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {
    addError, decrementLoading, incrementLoading,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {isAndroidApp} from "@/utils/isGoNative";
import {createWallet, WalletId, injectedProvider} from "devour-thirdweb/wallets";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import WalletConnectList from "@/components/WalletConnectList";
import FrameModalHeader from "@/components/modals/modalComponents/FrameModalHeader";

enum WalletConnectModalStatus {
    PROMPT_CONNECT = "PROMPT_CONNECT",
    FINISHED = "FINISHED",
}

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function WalletConnectRegistryModal(props: Props): ReactElement {

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        walletConnectModalStatus,
        setWalletConnectModalStatus,
    ] = useState<WalletConnectModalStatus>(WalletConnectModalStatus.PROMPT_CONNECT);
    const {refetch: refetchUserProfileData} = useGetUserProfile(fullToken);

    useEffect(() => {
        // Reset wallet status on modal open / close
        setWalletConnectModalStatus(WalletConnectModalStatus.PROMPT_CONNECT);
    }, [props.isOpen]);

    async function getWallets() {
        await refetchUserProfileData();
        const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
        dispatch(updateCurrentUser(userRes));
    }

    function renderWalletBody(): ReactNode {
        switch (walletConnectModalStatus) {
            case WalletConnectModalStatus.FINISHED:
                return renderWalletFinishedBody();
            case WalletConnectModalStatus.PROMPT_CONNECT:
                return renderWalletConnectBody();
            default:
                // Shouldn't ever get here.
                console.error("Error in rendering Waller Body.");
                return <div/>;
        }
    }

    function renderWalletFinishedBody(): ReactNode {
        return (
            <div className="wallet-connect-registry-modal_body_finished">
                <div className="wallet-connect-registry-modal_body_finished_check-con">
                    <IoIosCheckmarkCircle
                        className="wallet-connect-registry-modal_body_finished_check-con_check"
                    />
                </div>

                <p className="wallet-connect-registry-modal_body_finished_status">
										Wallet connected!
                </p>
            </div>
        );
    }

    async function onConnectWallet(walletId: WalletId, loadingOverlay?: boolean) {
        const isWalletInstalled = injectedProvider(walletId);
        if (isWalletInstalled && loadingOverlay) {
            dispatch(incrementLoading());
        }
        let walletAddress: string;
        let signature: string;
        try {
            const wallet = createWallet(walletId);
            await wallet.connect({ client: thirdwebAuthClient });
            const account = wallet.getAccount();
            walletAddress = account.address;
            /*
             * Can't do just `walletAddress` as the message because some wallets (like Trust Wallet)
             * do something weird and the signature won't verify.
             */
            signature = await account.signMessage({
                message: `I am the owner of ${walletAddress}`,
            });
        } catch (err) {
            // console.error("onConnectWallet create & sign", err);
            let errMessage: string = err.message || "Unable to connect wallet.";
            if (errMessage.includes("already pending for origin")) {
                // If user fails to sign but doesn't actually cancel, it will throw a duplicate request error.
                errMessage = "A request is already pending. Please accept or cancel it and try again.";
            }
            dispatch(await addError({
                type: ErrorType.APP,
                message: errMessage,
            }));
        }

        if (!signature || !walletAddress) {
            if (isWalletInstalled && loadingOverlay) {
                dispatch(decrementLoading());
            }
            return;
        }

        try {
            await new UsersApi(getConfig()).addWallet({
                addWalletRequestBody: {
                    signature,
                    publicKey: walletAddress,
                },
            });
            await getWallets();
            setWalletConnectModalStatus(WalletConnectModalStatus.FINISHED);
        } catch (err) {
            dispatch(await addError(err));
        } finally {
            if (isWalletInstalled && loadingOverlay) {
                dispatch(decrementLoading());
            }
        }
    }

    function renderWalletConnectBody(): ReactNode {
        return (
            <div className="wallet-connect-registry-modal_body_prompt-connect">
                <p>
										Don't have a wallet?{" "}
                    <a
                        href="https://metamask.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
												Click here to create one
                    </a>
                </p>

                <WalletConnectList onSelect={onConnectWallet} />
            </div>
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="wallet-connect-registry-modal"
        >
            <FrameModalHeader
                title="Wallet Connect"
                toggle={props.toggle}
            />
            <FrameModalBody className="wallet-connect-registry-modal_body">
                {renderWalletBody()}
                {isAndroidApp() &&
										<div className={"wallet-connect-registry-modal_body_android_app_disclaimer"}>
												Should you encounter difficulties when attempting to connect a wallet,
												we suggest using our{" "}
										    <a href={"https://devourgo.io"}>mobile web browser</a>
										    {" "}or the desktop version.
										</div>
                }
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default WalletConnectRegistryModal;
