import {ButtonHTMLAttributes, Dispatch, ReactElement, SetStateAction, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {NftPurchaseUpsert, NftPurchaseRequestBody} from "@devour/client";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {FaWallet} from "react-icons/fa";
import {isDesktop, isTablet} from "react-device-detect";
import {truncateMiddle} from "@/utils/truncateMiddle";
import {formatPriceAddCommaPreserveDecimals} from "@/utils/currencyFormatters";
import MintIndustryPassModalSelectPayments
    from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalSelectPayments";
import MintIndustryPassModalCreditPayment
    from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalCreditPayment";
import MintIndustryPassModalDpayPayment
    from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalDpayPayment";
import MintIndustryPassModalSplitPayment
    from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalSplitPayment";
import {PaymentStates} from "@/components/modals/mintIndustryPassModal/PaymentStates";
import Toast from "@/components/Toast";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import BuyIndustryPassConfirmModal from "@/components/modals/BuyIndustryPassConfirmModal";
import SignUpToast from "@/components/SignUpToast";
import { useStreamerMode } from "@/hooks/useStreamerMode";
import { useWalletDetailsModal } from "devour-thirdweb/react";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    nftPurchase: NftPurchaseUpsert;
    getClaimableNft: (values: NftPurchaseRequestBody) => Promise<void>;
    setPaymentState: Dispatch<SetStateAction<PaymentStates>>;
    onSubmit: () => Promise<void>;
    paymentState: PaymentStates;
}

function MintIndustryPassModalInProgress(props: Props): ReactElement {
    const {isOnDarkMode} = useThemePreference();
    const detailsModal = useWalletDetailsModal();
    const { hideCharacters } = useStreamerMode();
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const [
        showDpayErrorToast,
        setShowDpayErrorToast,
    ] = useState<boolean>(false);

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const INDUSTRY_PASS_PRICE = 150;
    const MAX_PASS_QTY = 10;
    const WALLET_TRUNCATE_LENGTH = 25;

    const nftPurchaseQuantity = props.nftPurchase?.quantity || 1;

    // Stripe has a minimum $0.50 requirement. Do not allow credit to go below this amount
    const minFiatAmount = 0.5;
    /*
     * Check if user can attempt to purchase another pass
     * Check if at max quantity
     * If pay by DPAY, check that user has enough for quantity + 1
     */
    const fullDpayBalanceInUsd = props.nftPurchase?.dpayBalance * props.nftPurchase?.dPayPricePerUsd;
    const qtyMinusOnePrice = (nftPurchaseQuantity - 1) * INDUSTRY_PASS_PRICE;
    const qtyPlusOnePrice = (nftPurchaseQuantity + 1) * INDUSTRY_PASS_PRICE;
    const canAddAnother: boolean = props.nftPurchase
        ? nftPurchaseQuantity < MAX_PASS_QTY &&
        (props.paymentState !== PaymentStates.DPAY || fullDpayBalanceInUsd >= qtyPlusOnePrice)
        : true;

    let paidWith: PaymentStates;
    if (props.nftPurchase?.stripeTotal > 0 && props.nftPurchase?.dpay === 0) {
        paidWith = PaymentStates.CREDIT;
    } else if (props.nftPurchase?.dpay > 0 && props.nftPurchase?.stripeTotal === 0) {
        paidWith = PaymentStates.DPAY;
    } else {
        paidWith = PaymentStates.SPLIT_AMOUNT_STAGE;
    }

    function handleToastDismissal() {
        setShowToast(false);
        setShowDpayErrorToast(false);
    }

    function subtractQty(): void {
        if (!fullToken) {
            setShowToast(true);
        } else if (nftPurchaseQuantity > 1) {
            let newDpayFiatUsed = props.nftPurchase.fiatInDpay;
            // Use the maximum amount of DPAY available
            switch (props.paymentState) {
                case PaymentStates.DPAY:
                    newDpayFiatUsed = Math.min(qtyMinusOnePrice, fullDpayBalanceInUsd);
                    break;
                case PaymentStates.SPLIT_CREDIT_STAGE:
                case PaymentStates.SPLIT_AMOUNT_STAGE:
                    newDpayFiatUsed = Math.min(qtyMinusOnePrice - minFiatAmount, fullDpayBalanceInUsd - minFiatAmount);
                    break;
            }
            void props.getClaimableNft({
                quantity: nftPurchaseQuantity - 1,
                fiatInDpay: newDpayFiatUsed,
            });
        }
    }

    function addQty(): void {
        if (!fullToken) {
            setShowToast(true);
        } else if (!props.nftPurchase) {
            setShowDpayErrorToast(true);
        } else if (canAddAnother) {
            let newDpayFiatUsed = props.nftPurchase.fiatInDpay;
            // Use the maximum amount of DPAY available
            switch (props.paymentState) {
                case PaymentStates.DPAY:
                    newDpayFiatUsed = Math.min(qtyPlusOnePrice, fullDpayBalanceInUsd);
                    break;
                case PaymentStates.SPLIT_CREDIT_STAGE:
                case PaymentStates.SPLIT_AMOUNT_STAGE:
                    newDpayFiatUsed = Math.min(qtyPlusOnePrice - minFiatAmount, fullDpayBalanceInUsd - minFiatAmount);
                    break;
            }
            void props.getClaimableNft({
                quantity: nftPurchaseQuantity + 1,
                fiatInDpay: newDpayFiatUsed,
            });
        }
    }

    function useThirdwebWallet(): void {
        detailsModal.open({
            client: thirdwebAuthClient,
            theme: isOnDarkMode ? "dark" : "light",
        });
    }

    function toggleConfirmModal() {
        setShowConfirmModal(s => !s);
    }

    function renderMintWallet(): ReactElement {
        if (props.nftPurchase?.walletAddress) {
            return (
                <div className="mint-industry-credit-card_body_wallet">
                    <div className="mint-industry-credit-card_body_wallet_mint">
                        <FaWallet className="mint-industry-credit-card_body_wallet_icon"/>
                        <p><strong>Mint to:</strong></p>
                    </div>
                    <div
                        className="mint-industry-credit-card_body_wallet_button"
                        onClick={useThirdwebWallet}
                    >
                        <p>{isDesktop || isTablet
                            ? hideCharacters(props.nftPurchase.walletAddress)
                            : truncateMiddle(props.nftPurchase.walletAddress, WALLET_TRUNCATE_LENGTH)}</p>
                    </div>
                </div>
            );
        }
        return null;
    }

    function formatPrice(priceInUSD: number) {
        let currPaymentState: PaymentStates;

        if (props.paymentState === PaymentStates.SUCCESS) {
            currPaymentState = paidWith;
        } else {
            currPaymentState = props.paymentState;
        }

        if (currPaymentState === PaymentStates.SELECT_PAYMENT || props.paymentState === PaymentStates.CREDIT) {
            return `$${priceInUSD.toFixed(2)} USD`;
        } if (currPaymentState === PaymentStates.DPAY) {
            return `${formatPriceAddCommaPreserveDecimals(Math.round(priceInUSD / props.nftPurchase.dPayPricePerUsd), 0)} ${import.meta.env.VITE_TOKEN_NAME}`;
        }
        return `${formatPriceAddCommaPreserveDecimals(Math.round(priceInUSD / props.nftPurchase.dPayPricePerUsd), 0)} ${import.meta.env.VITE_TOKEN_NAME} ($${priceInUSD.toFixed(2)} USD)`;

    }

    function renderPaymentState(): ReactElement {
        switch (props.paymentState) {
            case PaymentStates.SELECT_PAYMENT:
                return (
                    <MintIndustryPassModalSelectPayments
                        setShowToast={setShowToast}
                        setShowDpayErrorToast={setShowDpayErrorToast}
                        nftPurchase={props.nftPurchase}
                        getClaimableNft={props.getClaimableNft}
                        setPaymentState={props.setPaymentState}
                    />
                );
            case PaymentStates.CREDIT:
                return (
                    <MintIndustryPassModalCreditPayment
                        nftPurchase={props.nftPurchase}
                        onSubmit={() => setShowConfirmModal(true)}
                        getClaimableNft={props.getClaimableNft}
                        setPaymentState={props.setPaymentState}
                    />
                );
            case PaymentStates.DPAY:
                return (
                    <MintIndustryPassModalDpayPayment
                        onSubmit={() => setShowConfirmModal(true)}
                        nftPurchase={props.nftPurchase}
                        setPaymentState={props.setPaymentState}
                    />
                );
            default:
                return (
                    <MintIndustryPassModalSplitPayment
                        paymentState={props.paymentState}
                        setPaymentState={props.setPaymentState}
                        nftPurchase={props.nftPurchase}
                        onSubmit={() => setShowConfirmModal(true)}
                        getClaimableNft={props.getClaimableNft}
                    />
                );
        }
    }

    return (
        <>
            <SignUpToast
                message="Join DevourGO to unlock your Industry Pass!"
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <Toast
                duration={3000}
                message="Uh oh... there was a problem with fetching an NFT to mint. Please try again soon!"
                isOpen={showDpayErrorToast}
                variant="error"
                onDismiss={handleToastDismissal}
            />

            <BuyIndustryPassConfirmModal
                isOpen={showConfirmModal}
                toggle={toggleConfirmModal}
                onConfirm={props.onSubmit}
            />

            <h4>Number of Passes</h4>

            <div className="mint-industry-credit-card_body">

                {renderMintWallet()}

                <div className="mint-industry-credit-card_body_quantity-row">
                    <div className="mint-industry-credit-card_body_quantity-row_info-card">
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/industry-pass-circle.webp`}
                            alt="industry pass"
                        />
                        <div className="mint-industry-credit-card_body_quantity-row_info-card_description">
                            <h4>The Industry Pass</h4>
                            <p>A golden ticket to a world of benefits within the Devour ecosystem.</p>
                        </div>
                    </div>

                    <div className="mint-industry-credit-card_body_quantity-row_counter">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={subtractQty}
                            color="gray"
                            size="icon"
                            leftIcon={AiOutlineMinus}
                            forwardProps={{type: "button",
                                disabled: nftPurchaseQuantity <= 1}}

                        />
                        <div>
                            {nftPurchaseQuantity}
                        </div>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={addQty}
                            color="gray"
                            size="icon"
                            leftIcon={AiOutlinePlus}
                            forwardProps={{type: "button",
                                disabled: !canAddAnother}}
                        />
                    </div>
                </div>
            </div>

            <div className="mint-industry-credit-card_body_totals">

                <div className="mint-industry-credit-card_body_totals_row">
                    <p className="mint-industry-credit-card_body_totals_text">
                        Industry{isDesktop || isTablet
                            ? " Pass"
                            : "... "} x{nftPurchaseQuantity}
                    </p>
                    <p className="mint-industry-credit-card_body_totals_text">
                        {formatPrice(nftPurchaseQuantity * INDUSTRY_PASS_PRICE)}
                    </p>
                </div>

                <hr/>

                <div className="mint-industry-credit-card_body_totals_row">
                    <p>
                        <strong>
                            Total
                        </strong>
                    </p>
                    <p>
                        <strong>
                            {formatPrice(nftPurchaseQuantity * INDUSTRY_PASS_PRICE)}
                        </strong>
                    </p>
                </div>
            </div>

            <div className="mint-industry-credit-card_body_payment-options">
                {renderPaymentState()}
            </div>
        </>
    );
}

export default MintIndustryPassModalInProgress;
