import {ReactElement} from "react";
import useOnAuthThirdweb from "@/hooks/useOnAuthThirdweb";
import {useAutoConnect} from "devour-thirdweb/react";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";

/**
 * Auto login if user refresh or close their browser.
 * @constructor
 */
function ThirdwebSessionManager(): ReactElement {
    const {onLoginComplete} = useOnAuthThirdweb();

    useAutoConnect({
        client: thirdwebAuthClient,
        onConnect: (wallet) => onLoginComplete(wallet, false),
        // timeout,
    });

    return null;
}

export default ThirdwebSessionManager;