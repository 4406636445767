import {
    ButtonHTMLAttributes,
    FormEvent,
    ReactElement,
    useState,
} from "react";
import FrameButton from "../buttons/FrameButton";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {PatternFormat} from "react-number-format";
import {NumberFormatValues} from "react-number-format/types/types";

interface Props {
    hasError: boolean;
    onRequestNewCode: () => void;
    onSubmit: (code: string) => void;
}

function LoginVerificationCode(props: Props): ReactElement {
    const {isOnOverwolf} = useOverwolfInterop();
    const [code, setCode] = useState<string>("");

    function codeOnChange(input: NumberFormatValues): void {
        setCode(input.value);
    }

    function verifyWithCode(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        props.onSubmit(code);
    }

    return (
        <form onSubmit={verifyWithCode}>
            <div className="login-page_content_verification-code-container">
                <label>Verification Code</label>
                <PatternFormat
                    value={code}
                    valueIsNumericString={true}
                    type="tel"
                    format="######"
                    placeholder="Enter the 6 digit code sent to your email."
                    onValueChange={codeOnChange}
                    required={true}
                />
            </div>
            {props.hasError &&
								<>
								    <div className="login-page_content_verification-code-container_error">
												Verification code failed.<br/>
												Please try again or request a new code.
								    </div>
								    <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
								        color="ghost"
								        size="normal"
								        className="login-page_content_request-code-button"
								        forwardProps={{
								            type: "button",
								        }}
								        onClick={props.onRequestNewCode}
								    >
												Request New Code
								    </FrameButton>
								</>
            }
            <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                color={!isOnOverwolf ? "purple" : "purple-blue-gradient-shadow"}
                size="normal"
                className="login-page_content_login-button"
                forwardProps={{
                    type: "submit",
                }}
            >
								Login
            </FrameButton>
        </form>

    );
}

export default LoginVerificationCode;