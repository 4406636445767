import {ReactElement, useEffect, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router";
import {GiThreeFriends} from "react-icons/gi";
import {FiLogIn} from "react-icons/fi";
import {FaCog, FaDiscord, FaFacebook, FaInstagram, FaTwitter} from "react-icons/fa";
import {toggleLoginOpen, toggleSidebar} from "@/redux/meta/metaActions";
import {Token, UserType, UtilsApi, VersionResponse} from "@devour/client";
import {RiVipDiamondLine} from "react-icons/ri";
import {MdEmojiPeople, MdOutlineShoppingBag} from "react-icons/md";
import {AiOutlineHeart} from "react-icons/ai";
import {BiRestaurant} from "react-icons/bi";
import useWindowSize from "../../hooks/useWindowSize";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import useThemePreference from "@/hooks/useThemePreference";
import {useGate} from "statsig-react";
import BrandGoBackCard from "@/components/brands/BrandGoBackCard";
import {isMobile, isTablet} from "react-device-detect";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {DEVOUR_RESTAURANT_ID} from "@/pages/restaurants/RestaurantPage";
import {ENABLE_LOOT_SHOP_ACCESS_GATE_KEY, useScopedGate} from "@/hooks/useScopedGate";
import {FaRegAddressCard} from "react-icons/fa6";
import { useGetRedeemableNftOwnerships } from "@/hooks/useGetRedeemableNftOwnerships";
import GoVipRewardsInventoryDrawer from "../goVip/GoVipRewardsInventoryDrawer";
import { CgBox } from "react-icons/cg";
import { PiWalletBold } from "react-icons/pi";
import { TbLogout, TbNotes } from "react-icons/tb";
import { IoClose, IoGameControllerOutline} from "react-icons/io5";
import useOnLogout from "@/hooks/useOnLogout";
import useThirwebWallet from "@/hooks/useThirwebWallet";

function DevourSidebar(): ReactElement {

    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const location = useLocation();
    const isOnBrandPage = location.pathname.includes("/brands/");
    const navigate = useNavigate();
    const [
        version,
        setVersion,
    ] = useState<VersionResponse>();
    const {getThemedImageUrl} = useThemePreference();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const lastBrandMap = useSelector((store: IStore) => store.metaStore.lastBrandMap);
    const dispatch = useDispatch();
    const { onOpenThirdwebWallet } = useThirwebWallet();

    const {value: isLootShopEnabled} = useScopedGate(ENABLE_LOOT_SHOP_ACCESS_GATE_KEY);
    const [windowWidth] = useWindowSize();
    const isMobileView = windowWidth < 1260 || isMobile;
    const {isOnOverwolf} = useOverwolfInterop();
    const {devourLogout} = useOnLogout();

    useEffect(() => {
        new UtilsApi().version()
            .then(setVersion)
            .catch(console.error);
    }, []);

    const [
        showMyRewardsDrawer,
        setShowMyRewardsDrawer,
    ] = useState<boolean>(false);

    const {data: redeemableNftsResponse} = useGetRedeemableNftOwnerships(fullToken as Token);

    async function logoutHelper(): Promise<void> {
        toggleSidebarHelper();
        devourLogout();
        navigate("/");
    }

    function toggleSidebarHelper(): void {
        dispatch(toggleSidebar(false));
    }

    async function handleOrderFoodRouteForAuthedUser(): Promise<void> {
        toggleSidebarHelper();
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }
    }


    const renderFuelLogo = (isActive: boolean) => {
        const imageUrl = getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/sidebar-fuel-${isActive ? "active" : "default"}.webp`);
        return (
            <img src={imageUrl} alt="Fuel logo image" />
        );
    };

    return (
        <>
            {redeemableNftsResponse &&
                <GoVipRewardsInventoryDrawer
                    redeemableNfts={redeemableNftsResponse.nftOwnerships}
                    show={showMyRewardsDrawer}
                    toggle={() => setShowMyRewardsDrawer(false)}
                />
            }


            <div className={classNames("devour-sidebar", {
                "has-brand-map-card": lastBrandMap && !isOnBrandPage,
            })}>
                <div className="devour-sidebar_top">
                    <div
                        className="devour-sidebar_top_branding"
                        onClick={handleOrderFoodRouteForAuthedUser}
                    >
                        <img
                            src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-01.webp`)}
                            alt="DevourGO Logo"
                        />
                    </div>
                    <div className="devour-sidebar_top_title">
                        <div className="devour-sidebar_top_title_content">
                            <img
                                src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-01.webp`)}
                                alt="DevourGO Logo"
                            />
                            <IoClose onClick={() => dispatch(toggleSidebar(false))}/>
                        </div>
                    </div>
                    <div className="devour-sidebar_top_navigation">
                        {fullToken
                            ? <>
                                {isMobileView && !isOnBrandPage && <BrandGoBackCard isMobileCard={true}/>}
                                <FrameButton
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-order-food", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/restaurants") && !location.pathname.includes(DEVOUR_RESTAURANT_ID),
                                    })}
                                    leftIcon={BiRestaurant}
                                    onClick={handleOrderFoodRouteForAuthedUser}
                                >
                                Order Food
                                </FrameButton>

                                <FrameButton
                                    to="/favorites"
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-saved-restaurants", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/favorites"),
                                    })}
                                    leftIcon={AiOutlineHeart}
                                    onClick={toggleSidebarHelper}
                                >
                                Favorites
                                </FrameButton>

                                <FrameButton
                                    to="/menu-orders"
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-orders", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/menu-orders"),
                                    })}
                                    leftIcon={TbNotes}
                                    onClick={toggleSidebarHelper}
                                >
                              My Orders
                                </FrameButton>
                                <hr />

                                {isLootShopEnabled && <FrameButton
                                    to={`/restaurants/${DEVOUR_RESTAURANT_ID}`}
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-orders", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes(`/restaurants/${DEVOUR_RESTAURANT_ID}`),
                                    })}
                                    leftIcon={MdOutlineShoppingBag}
                                    onClick={toggleSidebarHelper}
                                >
                                Loot Shop
                                </FrameButton>
                                }

                                { !isOnOverwolf &&
                            <FrameButton
                                to="/go-vip-dashboard"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-govip", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/go-vip-dashboard"),
                                })}
                                leftIcon={RiVipDiamondLine}
                                onClick={toggleSidebarHelper}
                            >
                                GoVIP
                            </FrameButton>
                                }

                                <FrameButton
                                    to="/guilds"
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/guilds"),
                                    })}
                                    leftIcon={IoGameControllerOutline}
                                    onClick={toggleSidebarHelper}
                                >
                                Guilds
                                </FrameButton>

                                <FrameButton
                                    to="/gofriends"
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-gofrens", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/gofriends"),
                                    })}
                                    leftIcon={MdEmojiPeople}
                                    onClick={toggleSidebarHelper}
                                >
                                GoFriends
                                </FrameButton>
                                <FrameButton
                                    to="/the-industry-pass"
                                    color="ghost"
                                    size="normal"
                                    className={classNames(
                                        "devour-sidebar_top_navigation_button",
                                        "devour-sidebar_top_navigation_button-gofrens", {
                                            "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/the-industry-pass"),
                                        },
                                    )}
                                    leftIcon={FaRegAddressCard}
                                    onClick={toggleSidebarHelper}
                                >
                                Industry Pass
                                </FrameButton>

                                {dpayStatus &&
                                <FrameButton
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", /* "devour-sidebar_top_navigation_button-dpay", */ {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/load-fuel"),
                                    })}
                                    to={"/load-fuel"}
                                    onClick={toggleSidebarHelper}
                                >
                                    {renderFuelLogo(location.pathname.includes("/load-fuel"))}   Load {import.meta.env.VITE_TOKEN_NAME}
                                </FrameButton>
                                }

                                <hr />

                                <FrameButton
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-collectables")}
                                    leftIcon={CgBox}
                                    to="/collectibles"
                                    onClick={toggleSidebarHelper}
                                >
                                My Collectibles
                                </FrameButton>

                                <FrameButton
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-wallet")}
                                    leftIcon={PiWalletBold}
                                    onClick={onOpenThirdwebWallet}
                                >
                                Devour Wallet
                                </FrameButton>
                                <FrameButton
                                    to="/account"
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-account", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/account"),
                                    })}
                                    leftIcon={FaCog}
                                    onClick={toggleSidebarHelper}
                                >
                                Account
                                </FrameButton>

                                {currentUser?.user?.type === UserType.PARTNER && <FrameButton
                                    to={windowWidth > 768
                                        ? "/account/#affiliates"
                                        : "/affiliates"}
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-account", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/affiliates"),
                                    })}
                                    leftIcon={GiThreeFriends}
                                    onClick={toggleSidebarHelper}
                                >
                                    Affiliates
                                </FrameButton>
                                }

                                {(isMobileView || isTablet) && <>
                                    <hr />

                                    <FrameButton
                                        to="/account"
                                        color="ghost"
                                        size="normal"
                                        className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-logout")}
                                        leftIcon={TbLogout}
                                        onClick={logoutHelper}
                                    >
                                        Logout
                                    </FrameButton>
                                </>}
                            </>
                            : <>
                                {isMobileView && !isOnBrandPage && <BrandGoBackCard isMobileCard={true}/>}
                                <FrameButton
                                    to="/restaurants"
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-order-food", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/restaurants"),
                                    })}
                                    leftIcon={BiRestaurant}
                                    onClick={toggleSidebarHelper}
                                >
                                Order Food
                                </FrameButton>

                                { !isOnOverwolf &&
                            <FrameButton
                                to="/go-vip-dashboard"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-govip", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/go-vip-dashboard"),
                                })}
                                leftIcon={RiVipDiamondLine}
                                onClick={toggleSidebarHelper}
                            >
                                GoVIP
                            </FrameButton>
                                }

                                <FrameButton
                                    to="/guilds"
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/guilds"),
                                    })}
                                    leftIcon={IoGameControllerOutline}
                                    onClick={toggleSidebarHelper}
                                >
                                Guilds
                                </FrameButton>

                                <FrameButton
                                    to="/gofriends"
                                    color="ghost"
                                    size="normal"
                                    className={classNames(
                                        "devour-sidebar_top_navigation_button",
                                        "devour-sidebar_top_navigation_button-gofrens", {
                                            "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/gofriends"),
                                        },
                                    )}
                                    leftIcon={MdEmojiPeople}
                                    onClick={toggleSidebarHelper}
                                >
                                GoFriends
                                </FrameButton>

                                <FrameButton
                                    to="/the-industry-pass"
                                    color="ghost"
                                    size="normal"
                                    className={classNames(
                                        "devour-sidebar_top_navigation_button",
                                        "devour-sidebar_top_navigation_button-gofrens", {
                                            "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/the-industry-pass"),
                                        },
                                    )}
                                    leftIcon={FaRegAddressCard}
                                    onClick={toggleSidebarHelper}
                                >
                                Industry Pass
                                </FrameButton>

                                {dpayStatus &&
                                <FrameButton
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", /* "devour-sidebar_top_navigation_button-dpay", */ {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/load-fuel"),
                                    })}
                                    to={"/load-fuel"}
                                    onClick={toggleSidebarHelper}
                                >
                                    {renderFuelLogo(location.pathname.includes("/load-fuel"))}   Load {import.meta.env.VITE_TOKEN_NAME}
                                </FrameButton>
                                }
                                <hr />

                                <FrameButton
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-login", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/log-in"),
                                    })}
                                    leftIcon={FiLogIn}
                                    onClick={() => {
                                        dispatch(toggleLoginOpen(true));
                                        toggleSidebarHelper();
                                    }}
                                >
                                Log In
                                </FrameButton>
                            </>
                        }
                    </div>
                </div>

                <div className="devour-sidebar_bottom">
                    {!isMobileView && !isOnBrandPage && <BrandGoBackCard/>}

                    <div className="devour-sidebar_bottom_socials">
                        <FrameButton
                            color="gray"
                            size="icon-square"
                            leftIcon={FaTwitter}
                            href="https://twitter.com/GoDevour"
                            className="devour-sidebar_bottom_socials_btn"
                        />

                        <FrameButton
                            color="gray"
                            size="icon-square"
                            leftIcon={FaInstagram}
                            href="https://www.instagram.com/go_devour/"
                            className="devour-sidebar_bottom_socials_btn"
                        />

                        <FrameButton
                            color="gray"
                            size="icon-square"
                            leftIcon={FaFacebook}
                            href="https://www.facebook.com/GoDevour"
                            className="devour-sidebar_bottom_socials_btn"
                        />
                        <FrameButton
                            color="gray"
                            size="icon-square"
                            leftIcon={FaDiscord}
                            href="https://discord.gg/devourplay"
                            className="devour-sidebar_bottom_socials_btn"
                        />
                        {version &&
                    <p className="devour-sidebar_bottom_version">
                        Version: {version.version}
                    </p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default DevourSidebar;
