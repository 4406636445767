import {ReactElement} from "react";
import {LoginPayload, VerifyLoginPayloadParams} from "devour-thirdweb/auth";
import { ConnectButton } from "devour-thirdweb/react";
import useThemePreference from "@/hooks/useThemePreference";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useOnLogout from "@/hooks/useOnLogout";
import { ThirdwebApi } from "@devour/client";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import { base, mainnet, polygon } from "devour-thirdweb/chains";

function ThirdwebConnectButton(): ReactElement {
    const {isOnDarkMode} = useThemePreference();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {devourLogout} = useOnLogout();

    return (
        <ConnectButton
            theme={isOnDarkMode ? "dark" : "light"}
            client={thirdwebAuthClient}
            chains={[mainnet, base, polygon]}
            /**
             * Looking to authenticate with account abstraction enabled? Uncomment the following lines:
             *
             * accountAbstraction={{
             *  chain: sepolia,
             *  factoryAddress: "0x5cA3b8E5B82D826aF6E8e9BA9E4E8f95cbC177F4",
             *  gasless: true,
             * }}
             */
            auth={{
                /**
                 * 	`getLoginPayload` should @return {VerifyLoginPayloadParams} object.
                 * 	This can be generated on the server with the generatePayload method.
                 */
                getLoginPayload: async (params: {
                    address: string;
                }): Promise<LoginPayload> => {
                    return await new ThirdwebApi().getThirdwebLoginPayload({
                        address: params.address,
                        // chainId: sepolia.id.toString(),
                    }) as LoginPayload;
                },
                /**
                 * 	`doLogin` performs any logic necessary to log the user in using the signed payload.
                 * 	In this case, this means sending the payload to the server for it to set a JWT cookie for the user.
                 */
                doLogin: async (params: VerifyLoginPayloadParams) => {
                    await new ThirdwebApi().postThirdwebLoginPayload({
                        thirdwebLoginBody: {
                            signedPayload: params,
                        },
                    });
                },
                /**
                 * 	`isLoggedIn` returns true or false to signal if the user is logged in.
                 * 	Here, this is done by calling the server to check if the user has a valid JWT cookie set.
                 */
                isLoggedIn: async () => {
                    return Boolean(fullToken);
                },
                /**
                 * 	`doLogout` performs any logic necessary to log the user out.
                 * 	In this case, this means sending a request to the server to clear the JWT cookie.
                 */
                doLogout: async () => {
                    devourLogout();
                },
            }}
        />
    );
}

export default ThirdwebConnectButton;