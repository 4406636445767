import {FC, useState} from "react";
import {ErrorType, ThirdwebApi, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameButton from "./buttons/FrameButton";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateCurrentUser,
} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {sendMessageToOW} from "@/hooks/useOverwolfInterop";
import {useStreamerMode} from "@/hooks/useStreamerMode";
import {useLinkProfile, useProfiles, useUnlinkProfile} from "devour-thirdweb/react";
import {preAuthenticate} from "devour-thirdweb/wallets";
import {NumberFormatValues} from "react-number-format/types/types";
import {PatternFormat} from "react-number-format";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";

const UserEditEmail: FC = () => {
    const dispatch = useDispatch();
    const {hideInputText} = useStreamerMode();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const user = userProfileData?.user;
    const {mutateAsync: linkProfile} = useLinkProfile();
    const { mutateAsync: unlinkProfile } = useUnlinkProfile();
    const { data: connectedProfiles } = useProfiles({
        client: thirdwebAuthClient,
    });

    const [email, setEmail] = useState<string>(user?.email);
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [showEmailVerificationCode, setShowEmailVerificationCode] = useState<boolean>(false);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);

    async function onEmailUpdate() {
        dispatch(incrementLoading());

        try {
            const oldEmail = user?.email;
            const oldProfile = connectedProfiles.find((profile) => profile.details?.email === oldEmail);
            await unlinkProfile({
                client: thirdwebAuthClient,
                // Select any other profile you want to unlink
                profileToUnlink: oldProfile,
            });

            await new ThirdwebApi(getConfig()).updateThirdwebProfileEmail();

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
            // Tell Overwolf to update the user data
            sendMessageToOW({
                type: "de:update-user",
                payload: {userData: userRes},
            });
            setShowEmailVerificationCode(false);
        } catch (err) {
            dispatch(await addError(err));
        } finally {
            dispatch(decrementLoading());
            await refetchUserProfile();
        }

    }

    async function onAttemptEmailUpdate() {
        const existingRes = await new UsersApi(getConfig()).validateUserExistence({
            email: email,
        });
        if (existingRes._exists) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "Email already exists.",
            }));
            return;
        }
        setShowEmailVerificationCode(true);
        await preAuthenticate({
            client: thirdwebAuthClient,
            strategy: "email",
            email: email,
        });

    }

    async function onSubmitEmailUpdateCode() {
        try {
            await linkProfile({
                client: thirdwebAuthClient,
                strategy: "email",
                email: email,
                verificationCode: verificationCode,
            });
            await onEmailUpdate();
        } catch (err) {
            dispatch(await addError(err));
        }
    }

    function codeOnChange(input: NumberFormatValues): void {
        setVerificationCode(input.value);
    }

    return (
        <div className="user-edit_user-form_login-methods">
            <div className="user-edit_user-form_login-methods_label">
                <strong>Email</strong>
            </div>
            <div className="user-edit_user-form_login-methods_value">
                {showEmailVerificationCode
                    ? <PatternFormat
                        value={verificationCode}
                        valueIsNumericString={true}
                        type="tel"
                        format="######"
                        placeholder="Enter the 6 digit code sent to your email."
                        onValueChange={codeOnChange}
                    />
                    : <input
                        {...hideInputText()}
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                }
            </div>
            <div className="user-edit_user-form_login-methods_button">
                {showEmailVerificationCode
                    ? <FrameButton
                        color="purple"
                        size="narrow"
                        onClick={onSubmitEmailUpdateCode}
                    >
												Submit Code
                    </FrameButton>
                    : <FrameButton
                        color="purple"
                        size="narrow"
                        onClick={onAttemptEmailUpdate}
                    >
												Update Email
                    </FrameButton>
                }
            </div>
        </div>

    );
};

export default UserEditEmail;